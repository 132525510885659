<template>
  <!-- 首页 -->
  <div class="rootDiv pv-40">
    <div class="flex flex-ac">
      <div class="home_order flex flex-jb flex-w flex-n pv-30 ">
        <div class="w-full fs-22 fc-fff fw-bold ph-16">订单数据</div>
        <div class="order_state mb-20">
          <div class="fs-15">预售</div>
          <div class="fs-22 mt-10">2,268</div>
        </div>
        <div class="order_state mb-20">
          <div class="fs-15">现货</div>
          <div class="fs-22 mt-10">2,268</div>
        </div>
        <div class="order_state mb-20">
          <div class="fs-15">待发货</div>
          <div class="fs-22 mt-10">9,365</div>
        </div>
        <div class="order_state mb-20">
          <div class="fs-15">已发货</div>
          <div class="fs-22 mt-10">2,268</div>
        </div>
      </div>
      <div class="ml-30 flex-1">
        <div class="w-full">
          <div class="flex flex-jb">
            <div class="flex flex-ac fw-bold">
              <div class="fc-959595 pointer" :class="curIndex1 == 1?'fc-3b7cff fs-18':''" @click="curIndex1 = 1">
                订单新增变化
              </div>
              <test class="mv-10">/</test>
              <div class="fc-959595 pointer" :class="curIndex1 == 2?'fc-3b7cff fs-16':''" @click="curIndex1 = 2">
                小程序人员变化
              </div>
            </div>
            <div class="flex flex-ac">
              <div class="mr-10 fs-12 pv-10 ph-4 br-15 fc-959595 fw-bold pointer" :class="curIndex2 == 1?'haveClass':''"
                @click="curIndex2 = 1">月</div>
              <div class="mr-20 fs-12 pv-10 ph-4 br-15 fc-959595 fw-bold pointer" :class="curIndex2 == 2?'haveClass':''"
                @click="curIndex2 = 2">年</div>
            </div>
          </div>
          <div class="line_class" id="lineChart"></div>
        </div>
      </div>
    </div>
    <div class="flex flex-jb flex-ac">
      <div class="fs-20 fw-bold">商品</div>
      <div class="flex flex-jb flex-ac">
        <div class="mr-20 fs-12 pv-20 ph-8 br-15 fc-959595 fw-bold pointer" :class="curIndex3 == 1?'haveClass':''"
          @click="curIndex3 = 1">抽盒</div>
        <div class="mr-20 fs-12 pv-20 ph-8 br-15 fc-959595 fw-bold pointer" :class="curIndex3 == 2?'haveClass':''"
          @click="curIndex3 = 2">一番赏</div>
        <i class="el-icon-circle-plus fc-3b7cff fs-22" @click="addGoods"></i>
      </div>
    </div>
    <TableHeightFull class="tableDiv mt-20">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.coverImg" alt="" class="smallImg" v-if="scope.row.coverImg">
        </template>
      </EleTable>
    </TableHeightFull>
    <!-- 抽盒详情 -->
    <draws-details ref="draws-details" @refresh="getTableData"></draws-details>
    <!-- 一番赏详情 -->
    <reward-details ref="reward-details" @refresh="getTableData"></reward-details>
    <!-- 修改记录 -->
    <el-dialog title="修改记录" :visible.sync="dialogUseVisible">
      <div class="dialog-content">
        <EleTable ref="recordRef" slot="record" :tableData="recordData" :columns="recordColumns" height="100%"
          v-loading="recLoading">
        </EleTable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUseVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'home',
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "draws-details": (resolve) => require(["@/views/modules/goods/drawsDetails"], resolve),
    "reward-details": (resolve) => require(["@/views/modules/goods/rewardDetails"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'seriesTypes': state => state.dictList ? state.dictList.series_type : [],  //系列分类
      'shelves': state => state.dictList ? state.dictList.shelves : [],  //是否上下架
    })
  },
  data () {
    return {
      curIndex1: 1,
      curIndex2: 1,
      curIndex3: 1,
      tabLoading: false,
      recLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left' },
        { columnType: 'custom', label: '封面图', prop: 'img', align: 'left', width: '120', 'show-overflow-tooltip': true },
        { label: '系列名称', prop: 'seriesName', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '系列分类', prop: 'seriesType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.seriesTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        {
          columnType: 'fun', label: '是否上架', prop: 'shelves', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.shelves.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '价格', prop: 'soldPrice', align: 'left', 'show-overflow-tooltip': true },
        { label: '可兑换的积分', prop: 'integral', align: 'left', 'show-overflow-tooltip': true },
        { label: '发货日期', prop: 'deliverDate', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '记录', fn: this.recordList },
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogUseVisible: false,
      recordData: [],//修改记录
      recordColumns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '修改日期', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '修改用户', prop: 'username', align: 'left', 'show-overflow-tooltip': true },
        { label: '系列名称', prop: 'seriesName', align: 'left', 'show-overflow-tooltip': true },
        { label: '发货日期', prop: 'deliverDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '价格', prop: 'soldPrice', align: 'left', 'show-overflow-tooltip': true },
      ],
    }
  },
  mounted () {
    this.getTableData()
    this.getChart()
  },
  methods: {
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/boxSeries/findBoxSeries', {
        params: {
          currentPage: 1,
          pageSize: 5,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //打开折线图
    getChart () {
      let myChart = this.$echarts.init(document.getElementById('lineChart'))
      let option = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line'
          }
        ]
      }
      option && myChart.setOption(option)
    },
    //新增商品
    addGoods () {
      if (this.curIndex3 == 1) {
        this.$refs['draws-details'].init()
      } else if (this.curIndex3 == 2) {
        this.$refs['reward-details'].init()
      }
    },
    //删除
    deleteList (data) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        if (this.curIndex3 == 1) {
          this.$http.post('/boxSeries/removeBoxSeriesById', { id: data.id }).then(({ data: result }) => {
            this.getTableData()
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          })
        } else if (this.curIndex3 == 2) {
          this.$http.post('/goodSeries/removeGoodSeries', { id: data.id }).then(res => {
            this.getTableData()
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          })
        }
      }).catch(_ => { })
    },
    //编辑
    editList (row) {
      if (this.curIndex3 == 1) {
        this.$refs['draws-details'].init(row.id)
      } else if (this.curIndex3 == 2) {
        this.$refs['draws-details'].init(row.id)
      }
    },
    //记录
    recordList (row) {
      this.dialogUseVisible = true
      this.recLoading = true
      this.$http.get('/seriesEdit/findSeriesEdit', {
        params: {
          boxType: 3,
          seriesId: row.id,
        }
      }).then(({ data: result }) => {
        this.recordData = result.data
        this.recLoading = false
      }).catch((err) => {
        this.recLoading = false
      })
    },
  }
}
</script>

<style lang="less" scoped>
.rootDiv {
  .home_order {
    width: 350px;
    border-radius: 10px;
    background: #454d64;
    .order_state {
      padding: 20px;
      width: 150px;
      height: 110px;
      background-color: #f9fafc;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }
  .line_class {
    width: 100%;
    height: 360px;
  }
  .haveClass {
    background-color: #3b7cff;
    color: #fff !important;
  }
}
</style>